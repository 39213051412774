import _process from "process";
var exports = {};
var process = _process;
var has = Object.prototype.hasOwnProperty;
/**
 * Gather environment variables from various locations.
 *
 * @param {Object} environment The default environment variables.
 * @returns {Object} environment.
 * @api public
 */

function env(environment) {
  environment = environment || {};

  if ("object" === typeof process && "object" === typeof process.env) {
    env.merge(environment, process.env);
  }

  if ("undefined" !== typeof window) {
    if ("string" === window.name && window.name.length) {
      env.merge(environment, env.parse(window.name));
    }

    try {
      if (window.localStorage) {
        env.merge(environment, env.parse(window.localStorage.env || window.localStorage.debug));
      }
    } catch (e) {}

    if ("object" === typeof window.location && "string" === typeof window.location.hash && window.location.hash.length) {
      env.merge(environment, env.parse(window.location.hash.charAt(0) === "#" ? window.location.hash.slice(1) : window.location.hash));
    }
  } //
  // Also add lower case variants to the object for easy access.
  //


  var key, lower;

  for (key in environment) {
    lower = key.toLowerCase();

    if (!(lower in environment)) {
      environment[lower] = environment[key];
    }
  }

  return environment;
}
/**
 * A poor man's merge utility.
 *
 * @param {Object} base Object where the add object is merged in.
 * @param {Object} add Object that needs to be added to the base object.
 * @returns {Object} base
 * @api private
 */


env.merge = function merge(base, add) {
  for (var key in add) {
    if (has.call(add, key)) {
      base[key] = add[key];
    }
  }

  return base;
};
/**
 * A poor man's query string parser.
 *
 * @param {String} query The query string that needs to be parsed.
 * @returns {Object} Key value mapped query string.
 * @api private
 */


env.parse = function parse(query) {
  var parser = /([^=?&]+)=([^&]*)/g,
      result = {},
      part;
  if (!query) return result;

  for (; part = parser.exec(query); result[decodeURIComponent(part[1])] = decodeURIComponent(part[2]));

  return result.env || result;
}; //
// Expose the module
//


exports = env;
export default exports;